import { version } from './version';

export const environment = {
  BASE_URL: 'https://dtepiedade.giss.com.br/PlatformaDTe',
  production: false,
  product: 'dte',
  client: 'eicon',
  i18n: 'pt_br',
  version: version,
  i18nPath: './assets/i18n/',
  assetsPath: './assets/',
  powerbi: '3537800',
  schema: 'pbi_dte_piedade',
  groupId: '9e4ca03d-7e94-4ea8-af9a-2d7dff6f89ae',
  analiseGeral: 'df898526-eb7b-49e3-b043-6d1afd8640a0',
  analiseUsuarios: '93a79db3-776b-4b22-96a1-edbfdebd6000',
  analiseComunicados: 'a89dda07-e7d1-40aa-b88d-f5dfc341e73c',
  analiseProcurador: '06f7782a-69ec-4fd8-8268-3c6610dce29a',
  analiseEventos: 'dd7f48e5-3d39-4315-a773-ef8e249e88b7',
  analiseContribuintes: 'dd3d7c37-7355-4594-9ad7-54fa9e80f3af'
};
